<template>
  <div class="cart__header header--fix">
    <div class="page-header">
      <div class="container">
        <div class="page-header__wrapper">
          <BackBtn />
          <div class="page-header__name" v-if="headerName">
            {{ headerName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackBtn from "@/components/button/back-btn";

export default {
  components: {
    BackBtn,
  },

  props: {
    headerName: {
      type: String,
      required: false,
    },
  },
};
</script>
