<template>
  <div class="page-header__back" @click.prevent="goBackPage">
    <svg class="icon icon-arrow-left ">
      <use xlink:href="#arrow-left"></use>
    </svg>
  </div>
</template>

<script>
export default {
  methods: {
    goBackPage() {
      if (!window.history.state || !window.history.state.back) {
        this.$router.push("/")
        return
      }

      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
  },
};
</script>
