<template>
  <Welcome v-if="isAfterAuth"/>
  <template v-else>
    <Header v-if="isVisibleHeader()"/>
    <div class="page" :class="'page--' + $route.name">
      <router-view v-slot="{ Component }">
        <component :is="Component"/>
      </router-view>
    </div>
    <Footer/>
  </template>
</template>
<script>
import Header from "./header";
import Footer from "./footer";
import store from "@/store/index.js";
import Welcome from "@/components/welcome.vue";

export default {
  beforeRouteEnter(to, from, next) {
    if (!store.state.isLoaded) {
      store.dispatch("FIRST_LOAD_INFORMATION").then(() => {
        next();
      });
    } else {
      next();
    }
  },

  components: {
    Header,
    Footer,
    Welcome,
  },

  computed: {
    isAfterAuth() {
      return this.$store.state.auth.isAfterAuth;
    },

    currentRouteName() {
      return this.$route.name;
    }
  },

  methods: {
    isVisibleHeader() {
      let routerName = this.currentRouteName;
      const routerNames = [
        'home',
        'catalog-menu',
      ];

      if (routerNames.includes(routerName))
        return false;
      else
        return true;
    }
  }
};
</script>
