<template>
  <div class="">
    <button type="button" class="modal__close" @click="closeWeelcome()">
      <svg class="icon icon-cross">
        <use xlink:href="#cross"></use>
      </svg>
    </button>
    <div class="welcome container">
      <img src="/img/paladi.png" class="welcome__logo" />
      <div class="welcome__title">
        Добро пожаловать!
      </div>
      <div class="welcome__subtitle">
        Приятных покупок
      </div>
      <button class="btn welcome__btn" @click="closeWeelcome()">
        Перейти к покупкам
      </button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeWeelcome() {
      this.$store.commit("AUTH_SET_STATE", {
        key: "isAfterAuth",
        data: false,
      });
    },
  },
};
</script>
