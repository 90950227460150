<template>
  <footer class="footer">
    <div class="footer-links">
      <router-link
        class="footer-link"
        v-for="item in menuData"
        :key="item.id"
        :to="item.path"
        exact
      >
        <span class="footer-link__icon">
          <svg class="icon icon-heart" :class="'icon-' + item.picture">
            <use :xlink:href="'#' + item.picture"></use>
          </svg>
          <span class="footer-link__icon-count" v-if="item.id === 4 && basket.quantityBasket">{{ basket.quantityBasket }}</span>
        </span>
        <span class="footer-link__span">{{ item.id === 4 && basket.amountOrder ? basket.amountOrder + ' ₽' : item.title }}</span>
      </router-link>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    menuData() {
      return this.$store.state.menu.header;
    },

    basket() {
      if (this.$store.getters.isAuthenticated) {
        return this.$store.state.basket;
      } else {
        if (this.$store.state.basketGuest) {
          if (Object.keys(this.$store.state.basketGuest).length > 0)
            return {
              'quantityBasket': this.$store.state.basketGuest.length,
            };
        }

        return {
          'quantityBasket': 0,
        };
      }
    }
  },
};
</script>
